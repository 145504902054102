import React, { useState, useEffect } from "react";
import { Button, TextField, Slider, IconButton } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { Add, FileCopy, ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { useConfirm } from "material-ui-confirm";
import { useDebounce } from "use-debounce";
import { DateTime } from "luxon";
import styled from "styled-components";
import { useSnackbar } from "notistack";

import { arrayToAccessList, accessListEq } from "./UsageTab";
import { useFirebaseContext } from "../../../providers/firebase";
import { useMetoceanAPIContext } from "../../../providers/metoceanapi";
import {
  IApikey,
  IUsageItem,
  IEnterpriseSubscription,
  IAccessList,
} from "../../../../../types";
import { getAccessLevel } from "../magic";

interface ISortWith {
  direction: "asc" | "desc";
  field: "usage" | "createdAt";
}

const DeleteButton = styled.span`
  text-decoration: underline;
  :hover {
    cursor: pointer;
    color: red;
  }
`;

const getUsageForKey = (usageItems: IUsageItem[], key: string) => {
  return usageItems
    .filter((a) => a.key === key)
    .map((a) => a.charge)
    .reduce((sum, a) => (sum += a), 0);
};

const sortApikeys = (
  apikeys: IApikey[],
  sortWith: ISortWith,
  usageItems: IUsageItem[]
): IApikey[] => {
  if (sortWith.field === "createdAt") {
    const sortedApikeys = apikeys.sort((a, b) => {
      return a.createdAt > b.createdAt ? 1 : -1;
    });

    if (sortWith.direction === "desc") return sortedApikeys;
    return sortedApikeys.reverse();
  }
  if (sortWith.field === "usage") {
    const sortedApikeys = apikeys.sort((a, b) => {
      return getUsageForKey(usageItems, a.key) >
        getUsageForKey(usageItems, b.key)
        ? 1
        : -1;
    });
    if (sortWith.direction === "desc") return sortedApikeys;
    return sortedApikeys.reverse();
  }

  return apikeys;
};

const removeDisabledApikeys = (apikeys: IApikey[]) => {
  return apikeys.filter((a) => !a.deleted);
};

export const ApikeyManagement = () => {
  const { state, generateNewApikey, disableApikey, setApikey } =
    useFirebaseContext();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();

  const [sortWith, setSortWith] = useState<ISortWith>({
    direction: "desc",
    field: "usage",
  });

  const onDelete = (key: string) => {
    confirm({
      dialogProps: {
        open: true,
        maxWidth: "xs",
      },
      title: null,
      description: (
        <div style={{ textAlign: "center" }}>
          <div>Are you sure that you want to delete</div>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            {key}
          </div>
        </div>
      ),
      confirmationText: "Yes, delete this key",
      cancellationText: "Cancel",
      confirmationButtonProps: {
        color: "secondary",
      },
    })
      .then(() => disableApikey(key))
      .catch(() => console.log("dont delete"));
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          height: 48,
          backgroundColor: "#eef1f6",
          display: "flex",
          paddingLeft: 16,
          paddingRight: 16,
          justifyContent: "space-between",
          flexDirection: "row",
          textAlign: "center",
          position: "relative",
          alignItems: "center",
          borderTopRightRadius: 4,
          borderTopLeftRadius: 4,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={generateNewApikey}
          disabled={state.isUpdatingApikey}
          size="small"
        >
          Create API Key
        </Button>
        <div style={{ display: "flex", gap: 16 }}>
          <Button
            variant="text"
            endIcon={
              sortWith.field !== "createdAt" ? null : sortWith.direction ===
                "desc" ? (
                <ArrowDownward />
              ) : (
                <ArrowUpward />
              )
            }
            onClick={() => {
              setSortWith({
                ...sortWith,
                field: "createdAt",
                direction: sortWith.direction === "asc" ? "desc" : "asc",
              });
            }}
          >
            Created
          </Button>
          <Button
            variant="text"
            endIcon={
              sortWith.field !== "usage" ? null : sortWith.direction ===
                "desc" ? (
                <ArrowDownward />
              ) : (
                <ArrowUpward />
              )
            }
            onClick={() => {
              setSortWith({
                ...sortWith,
                field: "usage",
                direction: sortWith.direction === "asc" ? "desc" : "asc",
              });
            }}
          >
            Usage
          </Button>
        </div>
      </div>
      <div
        style={{
          borderBottomRightRadius: 4,
          borderBottomLeftRadius: 4,
          flex: 1,
          textAlign: "center",
        }}
      >
        {sortApikeys(
          removeDisabledApikeys(state.apikeys),
          sortWith,
          state.usageItems
        ).map((apikey, i, apikeys) => {
          return (
            <div
              key={apikey.key}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 4,
                borderBottom:
                  i === apikeys.length - 1 ? "" : "1px solid #ebebeb",
                padding: 12,
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  gap: 16,
                  width: "100%",
                  alignItems: "flex-end",
                }}
              >
                <div style={{ flex: 1, position: "relative" }}>
                  <TextField
                    style={{ width: "100%", flex: 1 }}
                    variant="outlined"
                    size="small"
                    value={apikey.key}
                  />
                  <IconButton
                    style={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                    }}
                    size="small"
                    disabled={state.isUpdatingApikey}
                    onClick={() => {
                      navigator.clipboard.writeText(apikey.key);
                      enqueueSnackbar("Copied to clipboard!", {
                        variant: "success",
                        anchorOrigin: {
                          horizontal: "right",
                          vertical: "top",
                        },
                      });
                    }}
                  >
                    <FileCopy style={{ fill: "#7599FF", fontSize: 18 }} />
                  </IconButton>
                </div>
                <div style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    placeholder="Write a note for this apikey"
                    value={apikey.note}
                    onChange={(e) => {
                      setApikey({ ...apikey, note: e.target.value });
                    }}
                    rowsMax={1}
                    style={{ verticalAlign: "bottom" }}
                  />
                </div>
              </div>

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: 16,
                  alignSelf: "stretch",
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    flex: 1,
                  }}
                >
                  {state.enterpriseSubscription && (
                    <div style={{ paddingLeft: 36, paddingRight: 36 }}>
                      <ApikeyAccessLevelSetter apikey={apikey} />
                    </div>
                  )}
                </div>

                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    fontSize: 14,
                    paddingBottom: 8,
                  }}
                >
                  <div style={{ flex: 1, textAlign: "left", color: "#666" }}>
                    <div>
                      Usage: {getUsageForKey(state.usageItems, apikey.key)}
                    </div>
                    <div>
                      Created:{" "}
                      {DateTime.fromISO(apikey.createdAt).toFormat(
                        "dd MMM, yyyy"
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      flex: 1,
                      textAlign: "right",
                      alignSelf: "flex-end",
                    }}
                  >
                    <DeleteButton onClick={() => onDelete(apikey.key)}>
                      Delete
                    </DeleteButton>
                  </div>
                </div>
              </div>
              {Object.keys(state.enterpriseSubscription?.wxtilesAccessList ?? {}).length > 0 ? (<div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "row",
                  gap: 16,
                  alignSelf: "stretch",
                }}
                >
                <WxtilesAccessListSetter apikey={apikey}/>
                </div>) : []}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface ApikeyAccessLevelSetterProps {
  apikey: IApikey;
}
const ApikeyAccessLevelSetter = ({ apikey }: ApikeyAccessLevelSetterProps) => {
  const { state, setAccessLevelForApikey } = useFirebaseContext();
  const [tempAccessLevel, setTempAccessLevel] = useState(
    apikey.accessLevels.forecast
  );

  const [debouncedAccessLevel] = useDebounce(tempAccessLevel, 1000);

  useEffect(() => {
    if (debouncedAccessLevel === apikey.accessLevels.forecast) return;
    setAccessLevelForApikey(apikey.key, debouncedAccessLevel);
  }, [debouncedAccessLevel]);

  useEffect(() => {
    setTempAccessLevel(apikey.accessLevels.forecast);
  }, [apikey.accessLevels.forecast]);

  const plans = state.plans.filter((p) => {
    if (!state.enterpriseSubscription) return false;
    return getAccessLevel(p) <= state.enterpriseSubscription.accessLevel;
  });

  return (
    <Slider
      step={null}
      min={Math.min(...plans.map(getAccessLevel))}
      max={Math.max(...plans.map(getAccessLevel))}
      value={tempAccessLevel}
      onChange={(e, value) => {
        setTempAccessLevel(value as number);
      }}
      marks={plans.map((plan) => {
        return {
          value: getAccessLevel(plan),
          label: plan.stripe_metadata_title,
        };
      })}
      />
  );
};

interface WxtilesAccessListSetterProps {
  apikey: IApikey;
}
const WxtilesAccessListSetter = ({ apikey }: WxtilesAccessListSetterProps) => {
  const wxtilesContext = useMetoceanAPIContext().datasets;
  const { state, setAccessListForApikey } = useFirebaseContext(); // setAccessListForApikey needs implemented in the backend
  const [tempAccessList, setTempAccessList] = useState(
    apikey?.accessLists?.wxtiles ?? {}
  );
  const subscriptionAccessList = state.enterpriseSubscription?.wxtilesAccessList ?? {};

  const [debouncedAccessList] = useDebounce(tempAccessList, 1000);

  useEffect(() => {
    if (!state.enterpriseSubscription) return;
    if (accessListEq(tempAccessList, apikey.accessLists?.wxtiles)) return;
    setAccessListForApikey(apikey.key, "wxtiles", debouncedAccessList);
  }, [debouncedAccessList]);

  useEffect(() => {
    setTempAccessList(apikey.accessLists?.wxtiles ?? {});
  }, [apikey.accessLists?.wxtiles]);

  return (
    <Autocomplete
      disabled={state.isUpdatingApikey}
      renderInput={(params) => <TextField {...params} variant="outlined" label="WxTiles Datasets" />}
      groupBy={(option) => {
        if (option === "*") {
          return 'Special';
        }
        if (wxtilesContext.has(option)) {
          return 'Live data';
        }
        return 'Other';
      }}
      options={ // * + dataset list if parent is *, else parent contents
        [...(subscriptionAccessList.hasOwnProperty("*") ? ["*", ...Array.from(wxtilesContext)] : Object.keys(subscriptionAccessList).sort())]
      }
      multiple
      fullWidth
      limitTags={4}
      value={Object.keys(tempAccessList ?? {}).sort()}
      freeSolo={subscriptionAccessList.hasOwnProperty("*")}
      getOptionDisabled={(option) =>
        Object.keys(tempAccessList ?? {}).length > 0 ? ((option === "*") !== (tempAccessList ?? {}).hasOwnProperty("*")) : false}
      onChange={async (e, newValues) => {
        // * must be the sole entry if it is present
        // other entries must be in the subscription access list unless that is *
        newValues = newValues.map((v) => v.trim());
        if (newValues.indexOf("*") >= 0) {
          newValues = ["*"];
        } else if (!subscriptionAccessList.hasOwnProperty("*")) {
          newValues = newValues.filter((v) => subscriptionAccessList.hasOwnProperty(v))
        }
        setTempAccessList(arrayToAccessList(newValues));
      }}
    />
  );
};
