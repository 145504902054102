/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useState,
  useLayoutEffect,
} from "react";
import { IModelSummary } from "../../../types";

interface IMetoceanAPI {
  summaries: IModelSummary[];
  datasets: Set<string>;
}
const MetoceanAPIContext = createContext<IMetoceanAPI>({
  summaries: [],
  datasets: new Set<string>(),
});

interface IMetoceanAPIState {
  summaries: IModelSummary[];
  datasets: Set<string>;
}
export const MetoceanAPIProvider = ({ children }: { children?: any }) => {
  const [state, setState] = useState<IMetoceanAPIState>({
    summaries: [],
    datasets: new Set<string>(),
  });

  useLayoutEffect(() => {
    Promise.all([
      fetch(
        `https://forecast-v2.metoceanapi.com/modelsummaries/?sort=area,asc&accessLevel=9001`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((a) => a.json() as Promise<IModelSummary[]>)
        .then((summaries) =>
          summaries
            .sort((a, b) => {
              return a.name > b.name ? 1 : -1;
            })
            .sort((a, b) => (a.accessLevel > b.accessLevel ? 1 : -1))
         ),
      fetch(
        `https://tiles.metoceanapi.com/data/meta.json`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((a) => a.json())
        .then((o) => new Set(Object.keys(o).sort()))
    ])
      .then(([summaries, datasets]) => setState({ ...state, summaries, datasets }));
  }, []);

  return <MetoceanAPIContext.Provider value={state} children={children} />;
};

export const useMetoceanAPIContext = () => useContext(MetoceanAPIContext);
