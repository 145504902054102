/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from "react";
import { config as firebaseConfig } from "../firebase-config";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import { useNavigate } from "react-router-dom";
import { uuid58 } from "uuid-base58";

import {
  IAccount,
  IApikey,
  IPlan,
  IPrice,
  IUser,
  INativeUser,
  IEnterpriseEnquiry,
  ISubscription,
  IEnterpriseSubscription,
  IUsageItem,
  IAccessList,
} from "../../../types";

export const tiers = ["starter", "basic", "plus", "pro", "enterprise"];
export const services = ["forecast"];

const app = firebase.apps.length
  ? firebase.app()
  : firebase.initializeApp(firebaseConfig); // This is needed for development

export interface IFirebaseState {
  user: IUser | null;
  nativeUser: INativeUser | null;
  isLoadingUser: boolean;
  accounts: IAccount[];
  isUpdatingApikey: boolean;
  plans: IPlan[];
  isSelectingPlan: boolean;
  app: firebase.app.App;
  subscription: ISubscription | null;
  apikeys: IApikey[];
  enterpriseSubscription: IEnterpriseSubscription | null;
  isLoadingUsage: boolean;
  usageItems: IUsageItem[];
}
interface IFirebase {
  signInWithGoogle: () => void;
  signInWithGithub: () => void;
  signInWithMicrosoft: () => void;
  signOut: () => void;
  generateNewApikey: () => void;
  disableApikey: (apikey: string) => void;
  beginSubscription: (planId: string) => Promise<null>;
  createSessionEditCard: () => Promise<void>;
  cancelSubscription: (subscriptionId: string) => void;
  createAccount: (account: Omit<IAccount, "id">) => Promise<void>;
  setUser: (userState: IUser) => void;
  addUserToAccount: (userId: string, accountId: string) => void;
  removeUserFromAccount: (userId: string, acocuntId: string) => void;
  setApikey: (a: IApikey) => void;
  setLimit: (a: number, accountId: string) => void;
  sendEnterpriseEnquiry: (enquiry: IEnterpriseEnquiry) => void;
  createEnterpriseSubscription: () => void;
  deleteEnterpriseSubscription: () => void;
  setAccessLevelForApikey: (apikey: string, accessLevel: number) => void;
  setAccessListForApikey: (apikey: string, api: string, accessList: IAccessList) => void;

  updateEnterpriseSubscription: (
    enterpriseSubscription: IEnterpriseSubscription
  ) => void;
  setEnterpriseCustomerId: (
    stripeCustomerId: string,
    enterpriseSubsriptionId: string
  ) => Promise<any>;
  state: IFirebaseState;
  isCreatingSession: boolean;
  isCancellingSubscription: boolean;
  isCreatingEditCardSession: boolean;
}

const defaultFirebaseState = {
  user: null,
  nativeUser: null,
  isLoadingUser: true,
  accounts: [],
  selectedAccountId: null,
  isUpdatingApikey: false,
  plans: [],
  subscriptionDocuments: [],
  isSelectingPlan: false,
  app,
  subscriptions: [],
  subscription: null,
  apikeys: [],
  enterpriseSubscription: null,
  isLoadingUsage: false,
  usageItems: [],
} as IFirebaseState;

const FirebaseContext = createContext<IFirebase>({
  signInWithGoogle: () => null,
  signInWithGithub: () => null,
  signInWithMicrosoft: () => null,
  signOut: () => null,
  generateNewApikey: () => null,
  disableApikey: () => null,
  beginSubscription: async () => null,
  cancelSubscription: () => null,
  createAccount: () => Promise.resolve(),
  setUser: () => null,
  addUserToAccount: () => null,
  removeUserFromAccount: () => null,
  setApikey: () => null,
  setLimit: () => null,
  sendEnterpriseEnquiry: () => Promise.resolve(),
  createEnterpriseSubscription: () => Promise.resolve(),
  deleteEnterpriseSubscription: () => Promise.resolve(),
  updateEnterpriseSubscription: () => Promise.resolve(),
  createSessionEditCard: () => Promise.resolve(),
  setAccessLevelForApikey: () => Promise.resolve(),
  setAccessListForApikey: () => Promise.resolve(),
  setEnterpriseCustomerId: () => Promise.resolve(),
  state: defaultFirebaseState,
  isCreatingSession: false,
  isCancellingSubscription: false,
  isCreatingEditCardSession: false,
});

const getNativeUser = async () => {
  const { currentUser } = firebase.auth();
  if (!currentUser) {
    return null;
  }
  return firebase
    .firestore()
    .collection("users")
    .doc(currentUser.uid)
    .get()
    .then((res) => res.data() as INativeUser)
    .catch((error) => {
      console.log("failed to getNativeUser");
      console.log(error);
      return null;
    });
};

const setNativeUser = async (nativeUser: INativeUser) => {
  const { currentUser } = firebase.auth();

  if (!currentUser) {
    console.log("tried to getNativeUser but was not logged in");
    return;
  }
  await firebase
    .firestore()
    .collection("users")
    .doc(currentUser.uid)
    .set(nativeUser)
    .catch((error) => {
      console.log("failed to setNativeUser");
      console.log(error);
      return null;
    });
  return nativeUser;
};

const getUser = async (uid: string) => {
  return firebase
    .firestore()
    .collection("users_by_email")
    .where("uids", "array-contains", uid)
    .get()
    .then((res) => res.docs.map((d) => d.data() as IUser))
    .then((users) => {
      const user = users[0];
      if (!user) return null;
      return user;
    })
    .catch((error) => {
      console.log("failed to getUser");
      console.log(error);
      return null;
    });
};

const getUserByEmail = async (email: string) => {
  return firebase
    .firestore()
    .collection("users_by_email")
    .doc(email)
    .get()
    .then((a) => a.data() as IUser)
    .then((user) => {
      if (!user) return null;
      return user;
    })
    .catch((error) => {
      console.log("failed to getUserByEmail");
      console.log(error);
      return null;
    });
};

const setUser = async (user: IUser) => {
  await firebase
    .firestore()
    .collection("users_by_email")
    .doc(user.email)
    .set(user)
    .catch((error) => {
      console.log("failed to set user");
      console.error(error);
    });
  return user;
};

const createAccount = async (
  account: Omit<IAccount, "id">
): Promise<string> => {
  const id = uuid58();
  await firebase
    .firestore()
    .collection("accounts")
    .doc(id)
    .set({ ...account, id, createdAt: new Date().toISOString() })
    .catch((error) => {
      console.log("failed to create account");
      console.log(error);
      return null;
    });

  await createApikey(id);
  return id;
};

const getAccounts = async (
  email: string,
  isAdmin = false
): Promise<IAccount[]> => {
  if (isAdmin) {
    const allAccounts = await firebase
      .firestore()
      .collection("accounts")
      .get()
      .then((res) => res.docs.map((d) => d.data() as IAccount));

    return allAccounts;
  }

  const existingAccounts = await firebase
    .firestore()
    .collection("accounts")
    .where("userIds", "array-contains", email)
    .get()
    .then((res) => {
      return res.docs.map((a) => a.data()) as IAccount[];
    })
    .catch((error) => {
      console.log("failed to get accounts");
      console.log(error);
      return null;
    });

  if (!existingAccounts) throw new Error("couldnt getAccounts");
  if (!existingAccounts.map((a) => a.name).includes("personal")) {
    await createAccount({
      name: "personal",
      apikeys: [],
      userIds: [email],
      isPersonal: true,
      creator: email,
      subscriptions2: [],
      enterpriseSubscriptionId: null,
      subscriptionId: null,
    });
    return getAccounts(email);
  }
  return existingAccounts;
};

const getAccount = async (accountId: string) => {
  return (await firebase
    .firestore()
    .collection("accounts")
    .doc(accountId)
    .get()
    .then((a) => a.data())
    .catch((error) => {
      console.log("failed to get account");
      console.log(error);
      return null;
    })) as IAccount | null;
};

const getSubscription = async (subscriptionId: string) => {
  return (await firebase
    .firestore()
    .collection("subscriptions")
    .doc(subscriptionId)
    .get()
    .then((a) => a.data())
    .catch((error) => {
      console.log("failed to get subscription");
      console.log(error);
      return null;
    })) as ISubscription | null;
};

const getEnterpriseSubscription = async (enterpriseSubscriptionId: string) => {
  return (await firebase
    .firestore()
    .collection("enterprise_subscriptions")
    .doc(enterpriseSubscriptionId)
    .get()
    .then((a) => a.data() ?? null)
    .catch((error) => {
      console.log("failed to get enterprise subscription");
      console.log(error);
      return null;
    })) as IEnterpriseSubscription | null;
};

const setEnterpriseSubscriptionFirebase = async (
  enterpriseSubscription: IEnterpriseSubscription
) => {
  return await firebase
    .firestore()
    .collection("enterprise_subscriptions")
    .doc(enterpriseSubscription.id)
    .set(enterpriseSubscription)
    .catch((error) => {
      console.log("failed to set account");
      console.log(error);
      return null;
    });
};

const setAccount = async (account: IAccount) => {
  return await firebase
    .firestore()
    .collection("accounts")
    .doc(account.id)
    .set(account)
    .catch((error) => {
      console.log("failed to set account");
      console.log(error);
      return null;
    });
};

const setSubscription = async (subscription: ISubscription) => {
  return await firebase
    .firestore()
    .collection("subscriptions")
    .doc(subscription.id)
    .set(subscription)
    .catch((error) => {
      console.log("failed to set subscription");
      console.log(error);
      return null;
    });
};

const setApikey = async (apikey: IApikey) => {
  return await firebase
    .firestore()
    .collection("keys")
    .doc(apikey.key)
    .set(apikey)
    .catch((error) => {
      console.log("failed to set apikey");
      console.log(error);
      return null;
    });
};

const getApikeys = async (accountId: string): Promise<IApikey[]> => {
  return firebase
    .firestore()
    .collection("keys")
    .where("accountId", "==", accountId)
    .get()
    .then((res) => {
      return res.docs.map((a) => a.data() as IApikey);
    })
    .catch((error) => {
      console.log({ accountId });
      console.log("failed to get apikeys");
      console.log(error);
      return [];
    });
};

const addUserToAccount = async (email: string, accountId: string) => {
  await fetch(
    "https://us-central1-metoceanapi-auth.cloudfunctions.net/addUserToAccount",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        email,
        accountId,
        jwt: await app.auth().currentUser?.getIdToken(),
      }),
    }
  ).catch((error) => {
    console.log("failed to add user to account");
    console.log(error);
  });
};

const setAccessLevelForApikey = async (key: string, accessLevel: number) => {
  await fetch(
    "https://us-central1-metoceanapi-auth.cloudfunctions.net/setAccessLevelForApikey",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        key,
        accessLevel,
        jwt: await app.auth().currentUser?.getIdToken(),
      }),
    }
  ).catch((error) => {
    console.log("failed to set access level for apikey");
    console.log(error);
  });
};

const setAccessListForApikey = async (key: string, api: string, accessList: IAccessList) => {
  await fetch(
    "https://us-central1-metoceanapi-auth.cloudfunctions.net/setAccessListForApikey",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        key,
        api,
        accessList,
        jwt: await app.auth().currentUser?.getIdToken(),
      }),
    }
  ).catch((error) => {
    console.log("failed to set access list for apikey");
    console.log(error);
  });
};

const createApikey = async (accountId: string) => {
  const account = await getAccount(accountId);
  if (!account) {
    console.log("failed to create apikey because could not getAccount");
    return null;
  }
  const apikey = uuid58();

  await firebase
    .firestore()
    .collection("keys")
    .doc(apikey)
    .set({
      key: apikey,
      accountId,
      deleted: false,
      limitReached: false,
      accessLevels: {
        forecast: 0,
      },
      createdAt: new Date().toISOString(),
      note: "",
    } as IApikey)
    .catch((error) => {
      console.log("failed to create apikey");
      console.log(error);
      return null;
    });
};

const removeUserFromAccount = async (email: string, accountId: string) => {
  const account = await getAccount(accountId);
  if (!account)
    throw new Error(
      "tried to remove a user from an account that does not exist"
    );

  return setAccount({
    ...account,
    userIds: account.userIds.filter((a) => a !== email),
  });
};

const createSession = async (productId: string) => {
  console.log("creating subscription session");
  return fetch(
    "https://us-central1-metoceanapi-auth.cloudfunctions.net/createSession",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        productId,
        jwt: await app.auth().currentUser?.getIdToken(),
      }),
    }
  ).then(async (res) => {
    console.log("received response from createSession");
    console.log(res);
    if (res.status !== 200) {
      console.log("failed to create subscription session");
      return null;
    }
    return res.json().then(({ url }) => url);
  });
};

const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.addScope("https://www.googleapis.com/auth/userinfo.email");

const githubProvider = new firebase.auth.GithubAuthProvider();
githubProvider.addScope("user:email");

const microsoftProvider = new firebase.auth.OAuthProvider("microsoft.com");
microsoftProvider.addScope("email");

const signInWithGoogle = () => app.auth().signInWithPopup(googleProvider);

const signInWithGithub = () => app.auth().signInWithPopup(githubProvider);

const signInWithMicrosoft = () => app.auth().signInWithPopup(microsoftProvider);

export const FirebaseProvider = ({ children }: { children?: any }) => {
  const navigate = useNavigate();
  const [state, setState] = useState<IFirebaseState>(defaultFirebaseState);
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [isCancellingSubscription, setIsCancellingSubscription] =
    useState(false);

  const [isCreatingEditCardSession, setIsCreatingEditCardSession] =
    useState(false);

  useLayoutEffect(() => {
    app.auth().onAuthStateChanged(async (user) => {
      const onFailToLogin = () => {
        setState((state) => ({ ...state, user: null, isLoadingUser: false }));
      };

      if (!user) return onFailToLogin();

      const providerData = user.providerData[0];
      if (!providerData) return onFailToLogin();

      const email = providerData.email;
      if (!email) return onFailToLogin();

      const existingNativeUser =
        (await getNativeUser()) ??
        (await setNativeUser({
          id: user.uid,
          email,
          provider: providerData.providerId,
        }));

      if (!existingNativeUser) {
        console.log(
          "could not create nativeUser, something has gone horribly wrong"
        );
        return;
      }

      const existingUser = await getUserByEmail(email);

      if (!existingUser) {
        const accounts = await getAccounts(email);
        const personalAccount = accounts.find(
          (a) => a.name === "personal"
        ) as IAccount;

        await setUser({
          email,
          usingAccount: personalAccount.id,
          hasAcceptedTerms: false,
          uids: [user.uid],
          isAdmin: false,
        });
      } else {
        if (!existingUser.uids.includes(user.uid)) {
          await setUser({
            ...existingUser,
            uids: [...existingUser.uids, user.uid],
          });
        }
      }

      const newUser = await getUser(user.uid);

      setState((state) => ({
        ...state,
        user: newUser,
        nativeUser: existingNativeUser,
        isLoadingUser: false,
      }));
    });
  }, []);

  useEffect(() => {
    if (!state || !state.user || !state.user.usingAccount) return;

    (async () => {
      if (!state || !state.user || !state.user.usingAccount) return;
      const [accounts, apikeys] = await Promise.all([
        getAccounts(state.user.email, state.user.isAdmin),
        getApikeys(state.user.usingAccount),
      ]);

      const account = accounts.find((a) => a.id === state.user?.usingAccount);
      setState((state) => ({
        ...state,
        subscription: null,
        accounts,
        apikeys,
      }));
      if (account?.subscriptionId) {
        const internalSubscription = await getSubscription(
          account.subscriptionId
        );
        setState((state) => ({
          ...state,
          subscription: internalSubscription,
        }));
      }
    })();

    const unsubscribeFromAccounts = firebase
      .firestore()
      .collection(`accounts`)
      .doc(state.user.usingAccount)
      .onSnapshot(() => {
        if (!state || !state.user) return;
        getAccounts(state.user.email, state.user.isAdmin).then((accounts) => {
          setState((state) => ({ ...state, accounts }));
        });
      });

    const unsubscribeFromApikeys = firebase
      .firestore()
      .collection("keys")
      .where("accountId", "==", state.user.usingAccount)
      .onSnapshot(() => {
        if (!state || !state.user) return;
        getApikeys(state.user.usingAccount).then((apikeys) => {
          setState((state) => ({ ...state, apikeys }));
        });
      });

    return () => {
      unsubscribeFromAccounts();
      unsubscribeFromApikeys();
    };
  }, [state.user?.usingAccount]);

  useEffect(() => {
    if (!state.user) return;

    const unsubscribeFromSubscription = firebase
      .firestore()
      .collection("subscriptions")
      .where("accountId", "==", state.user.usingAccount)
      .onSnapshot(async () => {
        if (!state || !state.user) return;
        const account = await getAccount(state.user.usingAccount);
        if (!account || !account.subscriptionId) {
          setState((state) => ({ ...state, subscription: null }));
          return;
        }
        const subscription = await getSubscription(account.subscriptionId);
        setState((state) => ({ ...state, subscription }));
      });
    return () => {
      unsubscribeFromSubscription();
    };
  }, [
    state.accounts.find((a) => a.id === state.user?.usingAccount)
      ?.subscriptionId,
  ]);

  useEffect(() => {
    if (!state.user) return;

    const unsubscribeFromEnterpriseSubscription = firebase
      .firestore()
      .collection("enterprise_subscriptions")
      .where("accountId", "==", state.user.usingAccount)
      .onSnapshot(async () => {
        if (!state || !state.user) return;
        const account = await getAccount(state.user.usingAccount);
        if (!account || !account.enterpriseSubscriptionId) {
          setState((state) => ({ ...state, enterpriseSubscription: null }));
          return;
        }
        const enterpriseSubscription = await getEnterpriseSubscription(
          account.enterpriseSubscriptionId
        );
        setState((state) => ({ ...state, enterpriseSubscription }));
      });

    return () => {
      unsubscribeFromEnterpriseSubscription();
    };
  }, [
    state.accounts.find((a) => a.id === state.user?.usingAccount)
      ?.enterpriseSubscriptionId,
  ]);

  useEffect(() => {
    (async () => {
      if (!state.user) return;

      if (!state.accounts.map((a) => a.id).includes(state.user.usingAccount)) {
        const personalAccount = state.accounts.find(
          (a) => a.name === "personal"
        );
        if (!personalAccount) return getAccounts(state.user.email); // this creates the personal account if it doesn't exist

        const user = await setUser({
          ...state.user,
          usingAccount: personalAccount.id,
        });
        setState((state) => ({
          ...state,
          user,
        }));
      }
    })();
  }, [state.accounts, state.user?.usingAccount]);

  useEffect(() => {
    if (!state.user && state.isLoadingUser === false) {
      navigate("/auth");
    } else {
      state.plans.length === 0 && setupPlans();
      navigate("/dashboard");
    }
  }, [state.user?.email, state.isLoadingUser]);

  useEffect(() => {
    const account = state.accounts.find(
      (a) => a.id === state.user?.usingAccount
    );
    if (
      !account ||
      !account.id ||
      (!account.subscriptionId && !account.enterpriseSubscriptionId) ||
      state.isLoadingUsage
    )
      return;

    setState((state) => ({ ...state, isLoadingUsage: true }));

    // Without the setTimeout, getUsage still thinks that the user is acting as the previous account - even though this useEffect is triggered when the usingAccount has changed.
    // A solution in the future for this is to make getUsage take the accountId as a prop
    setTimeout(async () => {
      const response = await fetch(
        "https://us-central1-metoceanapi-auth.cloudfunctions.net/getUsage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            jwt: await app.auth().currentUser?.getIdToken(),
          }),
        }
      );
      const { usageItems } = await response.json();
      setState((state) => ({ ...state, isLoadingUsage: false, usageItems }));
    }, 1000);
  }, [
    state.user?.usingAccount,
    state.accounts.find((a) => a.id === state.user?.usingAccount)
      ?.subscriptionId,
    state.accounts.find((a) => a.id === state.user?.usingAccount)
      ?.enterpriseSubscriptionId,
  ]);

  const signOut = async () => {
    await app.auth().signOut();
    setState((state) => ({ ...state, user: null }));
  };

  const generateNewApikey = async () => {
    if (state.user === null) return;
    setState((state) => ({ ...state, isUpdatingApikey: true }));
    await createApikey(state.user.usingAccount);
    setState((state) => ({ ...state, isUpdatingApikey: false }));
  };

  const disableApikey = async (key: string) => {
    if (state.user === null) return;
    const apikey = state.apikeys.find((a) => a.key === key);
    if (!apikey) return;

    setState((state) => ({ ...state, isUpdatingApikey: true }));
    await setApikey({ ...apikey, deleted: true });
    setState((state) => ({
      ...state,
      isUpdatingApikey: false,
    }));
  };

  const setupPlans = async () => {
    if (state.user === null) return;

    const plansWithoutPrices = await firebase
      .firestore()
      .collection("stripe_products")
      .where("active", "==", true)
      .where("metadata.stage", "==", "live")
      .get()
      .then((res) => {
        return res.docs.map((a) => ({ ...a.data(), id: a.id } as IPlan));
      });

    const plans = await Promise.all(
      plansWithoutPrices.map(async (plan) => {
        return {
          ...plan,
          prices: await firebase
            .firestore()
            .collection(`stripe_products/${plan.id}/prices`)
            .where("active", "==", true)
            .get()
            .then((a) => {
              return a.docs.map((b) => ({ ...b.data(), id: b.id } as IPrice));
            }),
        };
      })
    );

    setState((state) => ({ ...state, plans }));
  };

  const beginSubscription = async (planId: string) => {
    const plan = state.plans.find((p) => p.id === planId);
    if (planId !== "prod_LFJpP4wnW5OgPF") {
      if (!plan || !state.nativeUser) return null;
    }

    setState((state) => ({ ...state, isSelectingPlan: true }));
    setIsCreatingSession(true);
    const url = await createSession(planId);
    window.location = url;
    return null;
  };

  const createSessionEditCard = async () => {
    const response = await fetch(
      "https://us-central1-metoceanapi-auth.cloudfunctions.net/createSessionEditCard",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jwt: await app.auth().currentUser?.getIdToken(),
        }),
      }
    );
    const { url } = await response.json();
    window.location = url;
  };

  const cancelSubscription = async (subscriptionId: string) => {
    setState((state) => ({ ...state, isSelectingPlan: true }));

    setIsCancellingSubscription(true);
    await fetch(
      "https://us-central1-metoceanapi-auth.cloudfunctions.net/cancelSubscription",
      {
        method: "POST",
        headers: {
          //@ts-ignore
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        //@ts-ignore
        body: JSON.stringify({
          jwt: await app.auth().currentUser?.getIdToken(),
          subscriptionId,
        }),
      }
    ).catch((error) => {
      console.error(error);
    });
    setIsCancellingSubscription(false);
    setState((state) => ({ ...state, isSelectingPlan: false }));
  };

  const sendEnterpriseEnquiry = async (enquiry: IEnterpriseEnquiry) => {
    return fetch(
      "https://us-central1-metoceanapi-auth.cloudfunctions.net/sendEnterpriseEnquiryEmail",
      {
        method: "POST",
        headers: {
          //@ts-ignore
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        //@ts-ignore
        body: JSON.stringify(enquiry),
      }
    ).catch((error) => {
      console.error(error);
    });
  };

  const createEnterpriseSubscription = async () => {
    console.log("createEnterpriseSubscription");
    const account = state.accounts.find(
      (a) => a.id === state.user?.usingAccount
    );
    if (!state.user?.isAdmin || state.enterpriseSubscription || !account)
      return;

    const newEnterpriseSubscription: IEnterpriseSubscription = {
      id: uuid58(),
      accountId: state.user.usingAccount,
      createdAt: new Date().toISOString(),
      accessLevel: 50,
      createdBy: state.user.email,
      apiUnitsUsed: 0,
      lastUsageCheckTimestamp: "",
      note: "",
      limit: 1000 * 1000 * 1000,
    };

    await setEnterpriseSubscriptionFirebase(newEnterpriseSubscription);
    await setAccount({
      ...account,
      enterpriseSubscriptionId: newEnterpriseSubscription.id,
    });

    const [accounts, enterpriseSubscription] = await Promise.all([
      getAccounts(state.user.email),
      getEnterpriseSubscription(newEnterpriseSubscription.id),
    ]);
    setState((state) => ({ ...state, enterpriseSubscription, accounts }));
  };

  const deleteEnterpriseSubscription = async () => {
    const account = state.accounts.find(
      (a) => a.id === state.user?.usingAccount
    );
    if (!state.enterpriseSubscription || !account || !state.user) return;

    await updateEnterpriseSubscription({
      ...state.enterpriseSubscription,
      cancelledAt: new Date().toISOString(),
    });
    await setAccount({ ...account, enterpriseSubscriptionId: null });
    const accounts = await getAccounts(state.user.email);

    setState((state) => ({ ...state, accounts, enterpriseSubscription: null }));
  };

  const updateEnterpriseSubscription = async (
    updatedEnterpriseSubscription: IEnterpriseSubscription
  ) => {
    const account = state.accounts.find(
      (a) => a.id === state.user?.usingAccount
    );
    if (!state.enterpriseSubscription || !account || !state.user) return;
    await setEnterpriseSubscriptionFirebase(updatedEnterpriseSubscription);
    const enterpriseSubscription = await getEnterpriseSubscription(
      updatedEnterpriseSubscription.id
    );
    setState((state) => ({ ...state, enterpriseSubscription }));
  };

  const setEnterpriseCustomerId = async (
    stripeCustomerId: string,
    enterpriseSubscriptionId: string
  ) => {
    const response = await fetch(
      "https://us-central1-metoceanapi-auth.cloudfunctions.net/setEnterpriseCustomerId",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          jwt: await app.auth().currentUser?.getIdToken(),
          enterpriseSubscriptionId,
          stripeCustomerId,
        }),
      }
    );

    return await response.json();
  };

  return (
    <FirebaseContext.Provider
      value={{
        signInWithGoogle,
        signInWithGithub,
        signInWithMicrosoft,
        signOut,
        generateNewApikey,
        disableApikey,
        beginSubscription,
        cancelSubscription,
        createAccount: async (account: Omit<IAccount, "id">) => {
          if (!state || !state.user || !state.user.email) return;
          const accountId = await createAccount(account);
          const accounts = await getAccounts(state.user.email);
          const user = await setUser({
            ...state.user,
            usingAccount: accountId,
          });
          setState((state) => ({
            ...state,
            user,
            accounts,
          }));
        },
        addUserToAccount,
        removeUserFromAccount,
        setUser: (userState) => {
          setUser(userState);
          setState((state) => ({ ...state, user: userState }));
        },
        setApikey,
        setAccessLevelForApikey,
        setAccessListForApikey,
        setLimit: async (limit, accountId) => {
          const account = await getAccount(accountId);
          if (!account || !account.subscriptionId) {
            console.log("user tried to set limit without subscription");
            return;
          }

          const internalSubscription = await getSubscription(
            account.subscriptionId
          );
          if (!internalSubscription) return;

          if (limit < internalSubscription.apiUnitsUsed) return;
          await setSubscription({ ...internalSubscription, limit });
        },
        sendEnterpriseEnquiry,
        createEnterpriseSubscription,
        deleteEnterpriseSubscription,
        updateEnterpriseSubscription,
        createSessionEditCard: async () => {
          setIsCreatingEditCardSession(true);
          createSessionEditCard();
        },
        setEnterpriseCustomerId,
        state,
        isCreatingSession,
        isCancellingSubscription,
        isCreatingEditCardSession,
      }}
      children={children}
    />
  );
};

export const useFirebaseContext = () => useContext(FirebaseContext);
